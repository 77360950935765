import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Accordian from '../../common/components/Accordian/Accordian.js'
import Header from './components/header';
import FooterSection from './components/footersection';
import {trackAdditionalPageView} from "../../common/util/ccAnalyticsV2";


const FaqPage = () => {
    useEffect(() => {
        //trackAdditionalPageView('vanquish-faq-page');
    }, []);
    return (
        <>
            <div className="faqPageOuter bg-lite-blue">
                <Header/>
                <div className="container">
                    <div className="titleBar">
                        <h1 className="text-primary">FAQs.</h1>
                    </div>
                    <div className="faqsBox">
                        <div className="mb-3 center text-align-start">
                            <Accordian title={"What is Vanquish?"} >Vanquish is a tool that allows you to offset your vehicle’s carbon emissions. Each of our annual plans equates to a certain amount of carbon that gets offsets each year, via a number of projects.</Accordian>
                            <Accordian title={"Who is Vanquish?"} >We’re a New Zealand-based company, and have partnered with with CarbonClick to make the most of the best carbon offsetting projects going on right now.</Accordian>
                            <Accordian title={"Where does my money go? How do I know it has an impact?"} ><p>We are aligned with Kiwi-based carbon credit company, CarbonClick.</p>
                            <p>To enable our unique track and trace feature, CarbonClick pre-purchases carbon credits. CarbonClick’s transparent reimbursement model means that you can immediately see the retirement certificates from the projects you are supporting and know your contribution is having a direct impact.</p>
                            <p>A key feature of a carbon offsetting project is additionality, which means the project could not go ahead without the finance of carbon credits. CarbonClick rigorously analyses their projects to ensure that additionality criteria is being met and your money is having a direct impact.</p>
                            <p>To keep this project running, a small percentage of the plan price goes towards keeping the lights on and covering shipping.</p></Accordian>
                            <Accordian title={"How do I cancel my plan?"} >To cancel your plan email us at <a href="mailto:hello+vanquish@carbonclick.com" target="_blank" rel="noopener noreferrer">hello@carbonclick.com</a></Accordian>
                            <Accordian title={"Can I pay monthly?"} >Unfortunately right now we’re only offering annual plans.</Accordian>
                            <Accordian title={"Can I do a one-off payment?"} >Not just yet, but we will be offering this in the future.</Accordian>
                            <Accordian title={"What is a carbon credit?"} >A carbon credit is what is bought and sold when dealing with voluntary carbon offsets. For every tonne of CO2 that a verified project manages to absorb, avoid or otherwise reduce, a carbon credit can be issued. Carbon credits are certified by international standards and held in registries like the Gold Standard registry.</Accordian>
                            <Accordian title={"What is carbon offsetting?"} >Voluntary carbon offsetting is a way to compensate for unavoidable carbon emissions. To do this, organisations support projects that positively impact the environment and reduce or avoid carbon emissions that would have otherwise contributed to climate change. If the positive impact of this support negates the negative impact they have, ie: if the emissions avoided equal the original emissions of an activity, that activity is said to be “offset”.  In most circumstances, the activity or product can now be classified as carbon neutral as far as you’ve offset. Alternatively, a business may be able to offset this themselves and claim carbon neutrality or net zero carbon.</Accordian>
                            <Accordian title={"What is the meaning of \"Tree Growth Years\"?"} >According to the European Environment Agency, over one year a mature tree will absorb about 20 kilograms of carbon dioxide from the atmosphere. This metric "tree growth years" is a comparative metric, giving you an indication of how much carbon has been offset.</Accordian>
                            <Accordian title={"Where does Vanquish source it’s Carbon credits from?"} >
                                <p>We are aligned with Kiwi-based carbon credit company, CarbonClick.</p>
                                <p>For nature-based climate solutions, CarbonClick only works with reputable brokers who work directly with landowners who can give us full end-to-end transparency on the forest and movement of credits.</p><p>For international offsets, CarbonClick purchases from global voluntary offset market platforms like the UNFCCC, Carbon Trade Exchange, from brokers like Umwelt-Projekt-Management GmbH and CarbonBay. The projects sourced from all over the developing world. Information about particular projects is available to view on our projects page.</p>
                                <p></p>
                            </Accordian>
                            
                        </div>
                    </div>
                </div>
                <FooterSection/>
            </div>
        </>
    )
};


export default FaqPage;
