import React, { useState, useEffect } from 'react';
import logo from "../../common/media/cc_logo_white.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { formatNumber } from "../../common/util/numberFormatter";
import { projects as getProjects } from '../../common/api/projects';
import ProjectsList from './../Projects/ProjectsList';
import Header from './components/header';
import FooterSection from './components/footersection';
import {trackAdditionalPageView} from "../../common/util/ccAnalyticsV2";


const WhyPage = ({ merchantCode, fetchedImpact }) => {
    const [projects, setProjects] = useState();
    
    const numberOfContributions = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.numberOfContributions?.value);
    const carbonOffsetImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.carbonOffsetImpact?.value));
    const carbonOffsetImpactUnit = fetchedImpact && !fetchedImpact.error && fetchedImpact.carbonOffsetImpact?.unit.toLowerCase()
    const treeGrowthYears = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.treeGrowthYears?.value); 
    useEffect(() => {
        getProjects(null, merchantCode)
            .then(response => response.json())
            .then(data => setProjects(data))
            .catch(setProjects);
        //trackAdditionalPageView('vanquish-why-page');
    }, []);
    return (
        <>
            <div className="whyPageOuter">
                <Header/>
                <div className="w-100 hero-img bg-red whyPage banner-text-info">
                    <div className="container color-white text-align-start d-flex align-items-center">
                        <div className="ml-auto poTaxt">
                            <div className="mw-720px">
                                <h1 className="header text-shadow pb-2 mt-0 text-white">MAKING A <br/>DIFFERENCE.</h1>
                                <div className="mw-500 p-1 pt-2 welcome">
                                    <p className="text-shadow bnr-text lrg">So you finally bought the car that you dreamed of as a child, but it's not electric? Vanquish is here to help you to keep your eco warrior status, by choosing one of our carbon offsetting plans for your car.</p>
                                    <p className="text-shadow bnr-text lrg">We have a unique approach to carbon offsets which balances the desire to conserve and regenerate the local environment.</p>
                                    <p className="text-shadow bnr-text lrg last-child mb-0 pb-2">Depending on your locale, your offsets will automatically be sourced from a number of projects. Where available, it will be split between local flagship forest regeneration or conservation projects, and high-impact international clean energy projects.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* number section */}
                <div className="py-90 bg-grey">
                    <div className="container">
                        <div className="row text-primary">
                            <div className="col-md-6">
                                <h2 className="mb-5 pb-5">FIGHTING <br/>CLIMATE <br/>CHANGE <br/>AS A TEAM.</h2>
                            </div>
                            <div className="col-md-6">
                                <div className="icn-right-space">
                                    <div className="row text-align-start">

                                        <div className="col-md-12 col-sm-4 d-grid">
                                            <div className="w-100 text-sm-left text-right mb-60">
                                                <div className="img-cover img-1 w-100">
                                                    <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1667_wxrngc.svg`}/>
                                                </div>
                                                <div className="font-bold">
                                                    <h2 className="count-title mt-2 mb-2 text-black">{treeGrowthYears}</h2>
                                                    <p className="lrg mt-0 text-black"><i>Trees</i></p>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-4 d-grid">
                                            <div className="w-100 text-sm-left text-right mb-60">
                                                <div className="img-cover img-1 w-100">
                                                    <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1666_egnf25.svg`} />
                                                </div>
                                                <div className="font-bold">
                                                    <h2 className="count-title mt-2 mb-2 text-black">{carbonOffsetImpact}<small>{carbonOffsetImpactUnit}</small></h2>
                                                    <p className="lrg mt-0 text-black text-black"><i>Total offsets</i></p>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-4 d-grid">
                                            <div className="w-100 text-sm-left text-right">
                                                <div className="img-cover img-1 w-100">
                                                    <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1664_d11csb.svg`} />
                                                </div>
                                                <div className="font-bold">
                                                    <h2 className="count-title mt-2 mb-2 text-black">{numberOfContributions}</h2>
                                                    <p className="lrg mt-0 text-black"><i>Vanquish members</i></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /number section */}
                {/* highest section */}
                <div className="bg-lite-blue py-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mw-550">
                                    <h2 className="text-primary mb-5">HIGHEST <br/>QUALITY <br/>OFFSETS.</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-primary mx-513">
                                    <p className="lrg">We don't muck around. Your contribution goes directly to reforestation or high impact, clean energy projects; because we know that combined, these projects deliver the biggest climate impact.</p>
                                    <p className="lrg">Use Vanquish and you can wave goodbye to carbon from the atmosphere. Not only that, but you can feel good in the knowledge that you're helping to restore native ecosystems and creating sustainable green jobs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /highest section */}
                {/* Project section */}
                {Array.isArray(projects) && (
                <div className="py-90 bg-primary text-white">
                    <div className="container">
                        <div className="mb-4 pb-3">
                            <h2 className="font-bold text-lite-blue mb-3 pb-1">PROJECTS WE SUPPORT</h2>
                        </div>
                        <ProjectsList title={null} projects={projects} />
                    </div>
                </div>
                )}
                {/* /Project section */}
                <div className="bg-grey bg-footer"><FooterSection/></div>
            </div>
        </>
    )
};


export default WhyPage;
