import React from "react";
import PropTypes from "prop-types";
import { getLinkToProject } from "../../common/util/pathUtils";

const SingleColumn = ({ project, showLearnMore }) => {
    const linkToProject = getLinkToProject(project.code);

    return 	<div className="w-100 con-justify text-left">
				<div className="up">
					<div className="img-cover img-1 w-100">
						<a href={linkToProject}><img className="gray-scale" src={project.thumbnailImageUrl} /></a>
					</div>
					<div className="py-3 px-4">
						<h6 className="text-grey"><i>{project.locationDescription}</i></h6>
						<h3><a href={linkToProject} className="text-white font-bold font-exe">{project.name.toUpperCase()}</a></h3>
						<p className="xxsml mt-0 line-17">{project.shortDescription}</p>
					</div>
				</div>
				<div className="down px-4">
					{showLearnMore &&
					<a href={linkToProject} className="text-white font-bold xsml font-exe">LEARN MORE</a>
					}
				</div>
			</div>
}

SingleColumn.propTypes = {
    project: PropTypes.object,
    showLearnMore: PropTypes.bool
}

export default SingleColumn;