import React from 'react';
import logo from "../../../common/media/cc_logo_white.svg";
import { Link } from "react-router-dom";
import { footerLinks } from '../../../common/util/uiConfigs/footerConfiguration';


const FooterSection = () => {
    return (
        <div className="w-100 img-cover footer-img py-6" >
            <div className="container color-white">
                <p className="xxsml mt-0"><b>POWERED BY</b></p>
                <div className="d-flex">
                    <div>
                        <a href={footerLinks?.carbonclick} target="_blank" rel="noopener noreferrer">
                            <img style={{ height: 40 }} className="footer-logo" src={logo} alt="carbonclick" />
                        </a>
                    </div>
                </div>
                <hr className="mb-4" />

                <div className="row justify-content-between bottom-links">
                    <div className="col-md-auto">
                        <div>
                            <div className="mb-3 mobile-show"><a className="text-white medium" href="https://my.carbon.click" target="_blank" rel="noopener noreferrer">Log in to <u> My CarbonClick </u> to see the impact</a></div>
                            <div className="mb-3"><Link target="_blank" rel="noopener noreferrer" className="text-white" to="/why">Why Vanquish</Link></div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.aboutCarbonClick} target="_blank" rel="noopener noreferrer">About CarbonClick</a></div>
                            <div className="mb-3 mobile-hide">
                                <div className="d-flex align-items-center socialLinks">
                                    <a href="https://twitter.com/vanquishcarbon" target="_blank">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/v1637735191/Enterprise/Vanquish/twitter_pmqtst.svg" />
                                    </a>
                                    <a target="_blank" href="https://www.instagram.com/vanquish_carbon">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/e_colorize:100,co_rgb:e3dacf,f_png/v1639412966/Enterprise/Vanquish/instagram_ra0l4j.svg" />
                                    </a>
                                    <a target="_blank" href="https://medium.com/@vanquishcarbon">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/v1657104510/Enterprise/Vanquish/medium_qrmqkh.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></div>
                            <div className="mb-3"><a className="color-white" href={footerLinks?.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
                            <div className="mb-3"><Link className="color-white" to="/faq">FAQs</Link></div>
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div>
                            <div className="mb-3 mobile-hide"><a className="text-white medium" href={footerLinks?.myCarbonClick} target="_blank" rel="noopener noreferrer">Log in to <u> My CarbonClick </u> to see the impact</a></div>
                            <div className="mb-3"><a className="color-white" href="mailto:hello@vanquishcarbon.xyz" target="_blank" rel="noopener noreferrer">Contact us</a></div>
                            <div className="mb-3"><a className="color-white" href="mailto:hello+vanquish@carbonclick.com" target="_blank" rel="noopener noreferrer">hello@carbonclick.com</a></div>
                            <div className="mb-3 mobile-show">
                                <div className="d-flex align-items-center socialLinks">
                                    <a href="https://twitter.com/vanquishcarbon" target="_blank">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/v1637735191/Enterprise/Vanquish/twitter_pmqtst.svg" />
                                    </a>
                                    <a target="_blank" href="https://www.instagram.com/vanquish_carbon">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/v1639418755/Enterprise/Vanquish/instagram-square_1_u6m4jg.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};
    
export default FooterSection;