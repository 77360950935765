import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from './components/header';
import FooterSection from './components/footersection';
import {trackAdditionalPageView} from "../../common/util/ccAnalyticsV2";


const AboutPage = () => {
    useEffect(() => {
        //trackAdditionalPageView('vanquish-about-page');
    }, []);
    return (
        <>
            <div className="aboutPageOuter bg-blue">
                <Header/>
                <div className="container">
                    <div className="titleBar">
                        <h1 className="text-white mb-0">WE HAVE ARRIVED</h1>
                        <h2 className="text-white mt-0 subHeading"><i className="font-freight">to ease your conscience</i> <img src="https://res.cloudinary.com/carbonclick/image/upload/v1638779888/Enterprise/Vanquish/fullStop_qqf9ya.svg" /></h2>
                    </div>
                </div>
                <div className="container-fluid img-text-side-section">
                    {/* img text section */}
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="imgSide">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1644394406/Vanquish/About_1_su8x0i.jpg" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-7">
                            <div className="text-white">
                                <h3>WHAT WE’RE ABOUT.</h3>
                                <p className="lrg">Meet Vanquish – the new tool that makes it easy for you to offset your vehicle’s carbon emissions. </p>                                
                                <p className="lrg">You’ll notice that we look a bit different to what you’d expect a climate-friendly company to look like. We understand that green isn’t everyone's colour, but that doesn’t mean you don't care about the planet. We know that the existence of greenwashing puts a lot of people off climate-friendly projects, so we’ve arrived to break the stereotype and shake the sector up.</p>
                                <p className="lrg">Climate change is everybody’s issue, and we want to make sure everyone feels included in the fight for our planet.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="imgSide">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1644394402/Vanquish/About_2_xy0coe.jpg" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-7">
                            <div className="text-white">
                                <h3>WHAT’S THE GOAL?</h3>
                                <p className="lrg">No matter which way we spin it, unless vehicles are all 100% electric (come on Elon - you can do it), the world will always have carbon emissions created from transport. Our goal is to be part of the larger journey towards ‘net zero’ carbon, as not all emissions can be avoided and reduced. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="imgSide">
                                <img src="https://res.cloudinary.com/carbonclick/image/upload/v1644394402/Vanquish/About_3_jvylnc.jpg" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-7">
                            <div className="text-white">
                                <h3>CONTACT US</h3>
                                <p className="lrg">Like what you see? If you think you can help us with either awesome projects we can back, or becoming part of our team, we would love to hear from you.</p>
                                <p className="lrg mb-4">Or if you have any questions, get in touch below.</p>
                                <a href="mailto:hello@vanquishcarbon.xyz" className="btn btn-primary">GET IN TOUCH</a>
                            </div>
                        </div>
                    </div>
                    {/* /img text section */}
                </div>
                <div className="bg-grey bg-footer"><FooterSection/></div>
            </div>
        </>
    )
};


export default AboutPage;
