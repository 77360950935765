import React, { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Accordian from '../../common/components/Accordian/Accordian.js'
import Header from './components/header';
import FooterSection from './components/footersection';
import {trackAdditionalPageView} from "../../common/util/ccAnalyticsV2";

const WhatYouGetPage = () => {
    useEffect(() => {
        //trackAdditionalPageView('vanquish-what-you-get-page');
    }, []);

    return (
        <div className="whatYouGetPageOuter bg-grey">
          <Header/>

          <div className="container bg-grey">
              <div className="py-80-160">
              <div className="titleBar">
                  <h1 className="text-primary">WHAT YOU GET</h1>
                  <h2 style={{ textAlign: 'center' }} className="text-primary mt-0 subHeading">
                      <img className="what-img" style={{ top: 40, left: -40, transform: "rotate(-10deg)" }} src="https://res.cloudinary.com/carbonclick/image/upload/v1648560726/Vanquish/moon_ebovqa.svg" alt="" />
                      <i className="font-freight">with your plan</i>
                      <img className="what-img" style={{ top: -30, left: 60 }} src="https://res.cloudinary.com/carbonclick/image/upload/v1648560724/Vanquish/lines_cmjs86.svg" alt="" />
                  </h2>
              </div>
              </div>
          </div>

          <div className="aWofSticker bg-primary py-90">
              <div className="container">
                  <div className="row align-items-start">
                      <div className="col-lg-6">
                          <div className="mw-550 text-grey mobile-text-center">
                              <h3 className="mb-5">THE VANQUISH <br/>WOF STICKER.</h3>
                              <p className="lrg">The Vanquish WOF stickers have been designed for you to add some swag to your vehicle, and help spread the word.</p>
                              <p className="lrg">The stickers have an adhesive front, so they can easily be placed on the inside of your rear window.</p>
                              <p className="lrg">It covers the year of your plan, so you’ll get a fresh one everytime your subscription renews. Choice!</p>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="quoteBox text-center text-primary">
                              <img src="https://res.cloudinary.com/carbonclick/image/upload/v1648559548/Vanquish/group-cards_yupkvx.png" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="bg-lite-blue py-90">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-6">
                          <div className="mw-550 text-primary mobile-text-center">
                              <h3 className="mb-5">SOME VANQUISH SWAG.</h3>
                              <p className="lrg">Say what you really think with our first swag drop – Vanquish stickers.</p>
                              <p className="lrg">Your subscription will be sent out with a handful of limited edition stickers, so you an shamelessly preach the good word of the Vanquish cause.</p>
                              <p className="lrg">It covers the year of your plan, so you’ll get a fresh one everytime your subscription renews. Choice!</p>
                              <p className="lrg">As a continuous subscriber, you may receive some exciting new swag each year, and you’ll get it before it’s offered to the general public.</p>
                              <p className="lrg">Those of you who aren’t subscribed will have the opportunity to purchase Vanquish swag on our online store (coming soon).</p>
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="quoteBox text-center text-primary">
                              <img src="https://res.cloudinary.com/carbonclick/image/upload/v1657616984/Enterprise/Vanquish/zero-carbon-commuter_miinyz.png" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div className="bg-grey bg-footer">
            <FooterSection/>
          </div>
      </div>
    )
};


export default WhatYouGetPage;
