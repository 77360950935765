import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router";
import { Route, Switch } from "react-router-dom";
import '../common/css/global.css';
import './App.css';
import * as impact from '../common/api/impact';
import LandingPage from "./pages/LandingPage";
import ImpactPage from "./pages/ImpactPage";
import WhyPage from "./pages/WhyPage";
import FaqPage from './pages/FaqPage';
import AboutPage from './pages/AboutPage';
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import WhatYouGetPage from './pages/WhatYouGetPage';


function App() {
  const merchantCode = 'BES_NZ_VANQUISH';
  const [fetchedImpact, setfetchedImpact] = useState();
  const location = useLocation();
  useEffect(() => {
    if(!window.location.hash){
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    impact.merchants(merchantCode)
      .then(response => response.json())
      .then(body => {
        setfetchedImpact(body)
      })
      .catch(error => {
        console.error(error);
        setfetchedImpact(error)
      })
  }, []);

  return (
    <>

      <Switch>
        <Route
          exact
          path={`/about`}
          key="app-vanquish-about"
        >
          <AboutPage merchantCode={merchantCode} />
        </Route>
        <Route
          exact
          path={`/what-you-get`}
          key="app-vanquish-what-you-get"
        >
          <WhatYouGetPage />
        </Route>
        <Route
          exact
          path={`/faq`}
          key="app-vanquish-faq"
        >
          <FaqPage merchantCode={merchantCode} />
        </Route>
        <Route
          exact
          path={`/why`}
          key="app-vanquish-why"
        >
          <WhyPage fetchedImpact={fetchedImpact} merchantCode={merchantCode} />
        </Route>
        <Route
          exact
          path={`/impact`}
          key="app-vanquish-impact"
        >
          <ImpactPage merchantCode={merchantCode} />
        </Route>
        <Route
            exact
            path={`/success`}
            key="app-vanquish-success"
        >
          <PaymentSuccessPage/>
        </Route>
        <Route
          path={`/`}
          key="app-vanquish-landing"
        >
          <LandingPage fetchedImpact={fetchedImpact} merchantCode={merchantCode} />
        </Route>
      </Switch>
    </>
  );
}

export default App;
