import React, { useState, useEffect, useRef } from 'react';
import logo from "../../common/media/cc_logo_white.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { formatNumber } from "../../common/util/numberFormatter";
import { projects as getProjects } from '../../common/api/projects';
import ProjectsList from "./../Projects/ProjectsList";
import Header from './components/header';
import FooterSection from './components/footersection';
import {countryPlans, getPlanByCountryCodeAndPricingLevel, getPlansConfigByCountryCode} from "../utils/plansConfig";
import {HashLink} from "react-router-hash-link";

const LandingPage = ({ merchantCode, fetchedImpact }) => {
    const [projects, setProjects] = useState();

    const [selectedCountry, setSelectedCountry] = useState('NZ');

    const numberOfContributions = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.numberOfContributions?.value);
    const carbonOffsetImpact = fetchedImpact && !fetchedImpact.error && formatNumber(Math.round(fetchedImpact.carbonOffsetImpact?.value));
    const carbonOffsetImpactUnit = fetchedImpact && !fetchedImpact.error && fetchedImpact.carbonOffsetImpact?.unit.toLowerCase()
    const treeGrowthYears = fetchedImpact && !fetchedImpact.error && formatNumber(fetchedImpact.treeGrowthYears?.value);        
    useEffect(() => {
        getProjects(null, merchantCode).then(response => response.json()).then(data => setProjects(data));

    }, []);

    const [show1, setShow1] = React.useState();
    const [show2, setShow2] = React.useState();
    const [show3, setShow3] = React.useState();
    const [show4, setShow4] = React.useState();
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        document.body.classList.toggle('no-scroll');
        setActive(!isActive);
    };

    const handleChangeCountry = (event) => {
        setSelectedCountry(event.target.value);
    }

    const getCurrencyForCountry = (selectedCountry) => {
        let currentCurrency = null;

        Object.values(countryPlans).forEach(_ => {
            return currentCurrency = countryPlans[selectedCountry]?.currency;
        });

        return currentCurrency;
    }

    useEffect(() => {
    console.log(getCurrencyForCountry(selectedCountry));

    }, [selectedCountry]);

    return (
        <div className="landingPageOuter">
            <Header/>
            <div className="w-100 position-relative hero-img bg-primary banner-text-info">
                <div className="container color-white text-align-start d-flex align-items-end lp-wrapper">
                    <div className="pb-80 wrapper">
                        <div className="main">
                            <h1 className="text-shadow mb-2 text-white title">ON RED ALERT</h1>
                            <h2 className="text-white mb-5 subHeading w-100-vw"><i className="font-freight">for a green planet</i> <img src="https://res.cloudinary.com/carbonclick/image/upload/v1638779888/Enterprise/Vanquish/fullStop_qqf9ya.svg" /></h2>
                            <p className="text-shadow bnr-text lrg mw-450 first-child pt-90 mobile-bold">Vanquish is your one-stop-shop to offsetting your vehicle’s emissions.</p>
                            <p className="text-shadow bnr-text lrg mw-450 last-child">Offsetting is a powerful tool that neutralizes your carbon footprint with high quality carbon removal projects – so you can ride on with a clearer conscience.</p>
                            <HashLink className="btn btn-primary" to="/#plansAnchor">OFFSET MY VEHICLE</HashLink>
                        </div>
                        <div className="d-flex with-position">
                            <p className="mr-2 mt-0 text-light-grey xxsml mb-0">Powered by</p>
                            <div>
                                <a target="_blank" href="https://www.carbonclick.com/"><img style={{ height: 16 }} src={logo} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-90 bg-primary text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="text-left text-sm-center mb-4">OFFSET YOUR<br/> VEHICLES<br/> EMISSIONS</h2>
                            <div className='mobile-hide'>
                                <select className='bg-primary text-white font-exe lrg px-2 py-1' onChange={handleChangeCountry} value={selectedCountry}>
                                    {Object.keys(countryPlans).map(v => {
                                        return <option value={v} key={v}>{countryPlans[v].currency.toUpperCase()}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-left text-sm-center mt-1 mb-0 font-bold lrg">Different strokes for different folks – Vanquish understands that people want options, so we’ve developed plans that cater a range of lifestyles and climate ambitions.</p>
                            <p className="text-left text-sm-center mb-3 lrg">
                                Whether you’re just starting your climate change journey or you’re 
                                already in Daily Driver territory, you can rest in the knowledge that
                                you're funding the highest quality carbon offsetting projects around the world.
                            </p>
                        </div>
                        <div className='mobile-show mobile-select-container'>
                            <select className='bg-primary text-white font-exe lrg px-2 py-1' onChange={handleChangeCountry} value={selectedCountry}>
                                {Object.keys(countryPlans).map(v => {
                                    return <option value={v} key={v}>{countryPlans[v].currency.toUpperCase()}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <a id="plansAnchor"/>
                    <div className="priceTable">
                        <h2 className="text-grey textTitle" id="">Our plans</h2>
                        <div className="row pt-20">
                            <div className="col-xl-4 col-md-6 ">
                                <div className="priceBox bg-blue text-center">
                                    <div className="priceImg">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/w_600/v1644398004/Vanquish/Garage_Queen_Large_gmrui1.jpg" />
                                    </div>
                                    <div className="priceContent">
                                        <h3 className="heading">{getPlanByCountryCodeAndPricingLevel(selectedCountry, 10).name}</h3>
                                        <h4 className="mb-5">{getPlansConfigByCountryCode(selectedCountry).currencySymbol + getPlanByCountryCodeAndPricingLevel(selectedCountry, 10).price} {getCurrencyForCountry(selectedCountry)} / YEAR</h4>
                                        <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 10).paymentUrl} className="btn btn-primary">SELECT</a>
                                        <div className="plan-description">
                                            <p className="px-4 mb-5"><b>For those who drive less <br/> than 100km a week</b></p>
                                            <p className="px-4">You want to make a difference and do your bit to help.</p>
                                            <p className="px-4">You may already have a smaller carbon footprint and this is just another string in your bow, or you may just be starting out on your carbon reduction<br/> journey.</p>
                                        </div>
                                        <div className={`showing-price mt-5 ${show2 ? "show" : ""}`}>
                                            <div className="mb-5">
                                                <p className="mb-0"><b>Approximate yearly CO2 offset: </b></p>
                                                <h3 className="mt-1 mb-2">1700 KG</h3>
                                            </div>
                                            <p className="px-4 mb-5">As a part of your subscription, you can receive a Vanquish WOF sticker to put on your car.</p>
                                            <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 10).paymentUrl} className="btn btn-primary">SELECT</a>
                                        </div>
                                        <a onClick={() => setShow2(!show2)} href="javascript:void(0)" className="open-plan">
                                            <img src="https://res.cloudinary.com/carbonclick/image/upload/v1638421000/Enterprise/Vanquish/dwn-arw_ikzh79.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 ">
                                <div className="priceBox bg-grey text-center">
                                    <div className="priceImg">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/w_600/v1644398008/Vanquish/Weekend_Warrior_Large_sqkzdt.jpg" />
                                    </div>
                                    <div className="priceContent text-primary">
                                        <h3 className="heading">{getPlanByCountryCodeAndPricingLevel(selectedCountry, 20).name}</h3>
                                        <h4 className="mb-5">{getPlansConfigByCountryCode(selectedCountry).currencySymbol + getPlanByCountryCodeAndPricingLevel(selectedCountry, 20).price} {getCurrencyForCountry(selectedCountry)} / YEAR</h4>
                                        <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 20).paymentUrl} className="btn btn-olive">SELECT</a>
                                        <div className="plan-description">
                                            <p className="px-4 mb-5"><b>For those who drive <br/> 100-300km a week</b></p>
                                            <p className="px-4">Your car usage is well engrained in your lifestyle (how else are you going to get to Saturday brunch?).</p>
                                            <p className="px-4">You may not have a electric vehicle, but feel bad about the emissions your car is giving off and want to make it <br/> greener.</p>
                                        </div>
                                        <div className={`showing-price mt-5 ${show3 ? "show" : ""}`}>
                                            <div className="mb-5">
                                                <p className="mb-0"><b>Approximate yearly CO2 offset: </b></p>
                                                <h3 className="mt-1 mb-2">2600 KG</h3>
                                            </div>
                                            <p className="px-4 mb-5">As a part of your subscription, you can receive a Vanquish WOF sticker to put on your car.</p>
                                            <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 20).paymentUrl} className="btn btn-olive">SELECT</a>
                                        </div>
                                        <a onClick={() => setShow3(!show3)} href="javascript:void(0)" className="open-plan">
                                            <img src="https://res.cloudinary.com/carbonclick/image/upload/v1638421490/Enterprise/Vanquish/dwn-arw-drk_kyrpq5.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 ">
                                <div className="priceBox bg-lite-blue text-center">
                                    <div className="priceImg">
                                        <img src="https://res.cloudinary.com/carbonclick/image/upload/w_600/v1644398009/Vanquish/Daily_Driver_Large_b7rr0n.jpg" />
                                    </div>
                                    <div className="priceContent text-primary">
                                        <h3 className="heading">{getPlanByCountryCodeAndPricingLevel(selectedCountry, 30).name}</h3>
                                        <h4 className="mb-5">{getPlansConfigByCountryCode(selectedCountry).currencySymbol + getPlanByCountryCodeAndPricingLevel(selectedCountry, 30).price} {getCurrencyForCountry(selectedCountry)} / YEAR</h4>
                                        <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 30).paymentUrl} className="btn btn-olive">SELECT</a>
                                        <div className="plan-description">
                                            <p className="px-4 mb-5"><b>For those who drive more <br/> than 300km a week</b></p>
                                            <p className="px-4">You know what they say about big feet... Big carbon footprint.</p>
                                            <p className="px-4">You love your car and you’re in and out of it every day.</p>
                                            <p className="px-4">If your car is a thirsty gas guzzler, or you drive a lot each week – this is the plan for you.</p>
                                        </div>
                                        <div className={`showing-price mt-5 ${show4 ? "show" : ""}`}>
                                            <div className="mb-5">
                                                <p className="mb-0"><b>Approximate yearly CO2 offset: </b></p>
                                                <h3 className="mt-1 mb-2">4000 KG</h3>
                                            </div>
                                            <p className="px-4 mb-5">As a part of your subscription, you can receive a Vanquish WOF sticker to put on your car.</p>
                                            <a href={getPlanByCountryCodeAndPricingLevel(selectedCountry, 30).paymentUrl} className="btn btn-olive">SELECT</a>
                                        </div>
                                        <a onClick={() => setShow4(!show4)} href="javascript:void(0)" className="open-plan">
                                            <img src="https://res.cloudinary.com/carbonclick/image/upload/v1638421490/Enterprise/Vanquish/dwn-arw-drk_kyrpq5.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whyOffset py-90">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="mw-550">
                                <h2 className="text-primary mb-5">WHY <br/>OFFSET?</h2>
                                <p className="lrg">We’re seeing a growing transformation across the planet, but it’s a long journey ahead and we need climate action now. Reducing your carbon footprint and offsetting the remainder are some of the most impactful ways to limit your environmental impact. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="quoteBox text-center text-primary">
                                <img className="mb-5" src="https://res.cloudinary.com/carbonclick/image/upload/v1637735193/Enterprise/Vanquish/quote_az76f9.svg" />
                                <i className="d-block font-freight">Cars and trucks account for</i>
                                <span className="d-block bigger font-exe">15-20<span>%</span></span>
                                <i className="d-block font-freight">of global carbon emissions</i>
                                <img className="mt-5" src="https://res.cloudinary.com/carbonclick/image/upload/v1637735193/Enterprise/Vanquish/quote_az76f9.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-90 bg-blue text-white">
                <div className="container">
                    <div className="mb-4 pb-3">
                        <h2 className="font-bold mb-3 pb-1">WHERE DOES <br/>MY MONEY GO?</h2>
                    </div>
                    <ProjectsList title={null} projects={projects} />
                </div>
            </div>

            <div className="py-90 bg-grey">
                <div className="container">
                    <div className="row text-primary">
                        <div className="col-md-6">
                            <h2 className="mb-5 pb-5">FIGHTING <br/>CLIMATE <br/>CHANGE <br/>AS A TEAM.</h2>
                        </div>
                        <div className="col-md-6">
                            <div className="icn-right-space">
                                <div className="row text-align-start">

                                    <div className="col-md-12 col-sm-4 d-grid">
                                        <div className="w-100 text-sm-left text-right mb-60">
                                            <div className="img-cover img-1 w-100">
                                                <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1667_wxrngc.svg`}/>
                                            </div>
                                            <div className="font-bold">
                                                <h2 className="count-title mt-2 mb-2 text-black">{treeGrowthYears}</h2>
                                                <p className="lrg mt-0 text-black"><i className="font-freight">Trees</i></p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-4 d-grid">
                                        <div className="w-100 text-sm-left text-right mb-60">
                                            <div className="img-cover img-1 w-100">
                                                <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1666_egnf25.svg`} />
                                            </div>
                                            <div className="font-bold">
                                                <h2 className="count-title mt-2 mb-2 text-black">{carbonOffsetImpact}<small>{carbonOffsetImpactUnit}</small></h2>
                                                <p className="lrg mt-0 text-black text-black"><i className="font-freight">Total offsets</i></p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-md-12 col-sm-4 d-grid">
                                        <div className="w-100 text-sm-left text-right">
                                            <div className="img-cover img-1 w-100">
                                                <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638520482/Enterprise/Vanquish/Group_1664_d11csb.svg`} />
                                            </div>
                                            <div className="font-bold">
                                                <h2 className="count-title mt-2 mb-2 text-black">{numberOfContributions}</h2>
                                                <p className="lrg mt-0 text-black"><i className="font-freight">Vanquish members</i></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterSection/>
        </div>
    )
}


export default LandingPage;