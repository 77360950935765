import config from "../../../config";

const paymentUrlConfig = {
    development: {
        NZ: new Map([
            [10, 'https://buy.stripe.com/test_cN29CDaPN0Ja8k83cg'],
            [20, 'https://buy.stripe.com/test_cN2aGH3nl77yeIw7sz'],
            [30, 'https://buy.stripe.com/test_00g6qre1ZeA0eIwcMW'],
        ]),
        US: new Map([
            [10, 'https://buy.stripe.com/test_00g023bTR9fG7g48wC'],
            [20, 'https://buy.stripe.com/test_14k1679LJ2RidEs9AJ'],
            [30, 'https://buy.stripe.com/test_3cs1673nl0Ja8k86oA'],
        ]),
        AU: new Map([
            [10, 'https://buy.stripe.com/test_3csg111fdgI8dEsaEJ'],
            [20, 'https://buy.stripe.com/test_aEU1675vt0Ja1VK7sA'],
            [30, 'https://buy.stripe.com/test_cN25mnbTR9fGfMA5kv'],
        ])
    },
    qual: {
        NZ: new Map([
            [10, 'https://buy.stripe.com/test_cN29CDaPN0Ja8k83cg'],
            [20, 'https://buy.stripe.com/test_cN2aGH3nl77yeIw7sz'],
            [30, 'https://buy.stripe.com/test_00g6qre1ZeA0eIwcMW'],
        ]),
        US: new Map([
            [10, 'https://buy.stripe.com/test_00g023bTR9fG7g48wC'],
            [20, 'https://buy.stripe.com/test_14k1679LJ2RidEs9AJ'],
            [30, 'https://buy.stripe.com/test_3cs1673nl0Ja8k86oA'],
        ]),
        AU: new Map([
            [10, 'https://buy.stripe.com/test_3csg111fdgI8dEsaEJ'],
            [20, 'https://buy.stripe.com/test_aEU1675vt0Ja1VK7sA'],
            [30, 'https://buy.stripe.com/test_cN25mnbTR9fGfMA5kv'],
        ])
    },
    prod: {
        NZ: new Map([
            [10, 'https://buy.stripe.com/dR67wxaOJ82o2CQ4gg'],
            [20, 'https://buy.stripe.com/3csaIJaOJgyUgtGeUV'],
            [30, 'https://buy.stripe.com/3cs6stf4ZgyU1yM4gi'],
        ]),
        US: new Map([
            [10, 'https://buy.stripe.com/5kAbMNcWRciEgtG006'],
            [20, 'https://buy.stripe.com/00g3gh9KFeqMcdq9AH'],
            [30, 'https://buy.stripe.com/14kg331e9fuQ7XabIQ'],
        ]),
        AU: new Map([
            [10, 'https://buy.stripe.com/cN27wx4qlfuQ1yMdQT'],
            [20, 'https://buy.stripe.com/dR60456yt4Qc2CQ004'],
            [30, 'https://buy.stripe.com/14k4kl6yt1E01yMfZ3'],
        ])
    },
}

const getPaymentUrlByCountryAndPricingLevel = (countryCode, pricingLevel) => {
    let environment = config.deployment_env || 'development';
    environment = (environment === 'local' ? 'development' : environment)
    return paymentUrlConfig[environment][countryCode].get(pricingLevel);
}

const countryPlans = {
    NZ: {
        currency:'NZD',
        currencySymbol: '$',
        countryName: 'New Zealand',
        plans:[
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('NZ', 10),
                pricingLevel: 10,
                price: '129.00',
                typeformId: 'yzUHIzk7',
                name: 'GARAGE QUEEN'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('NZ', 20),
                pricingLevel: 20,
                price: '199.00',
                typeformId: 'BrBFNMIw',
                name: 'WEEKEND WARRIOR'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('NZ', 30),
                pricingLevel: 30,
                price: '299.00',
                typeformId: 'snzpVwAH',
                name: 'DAILY DRIVER'
            },
        ]
    },
    US: {
        currency:'USD',
        currencySymbol: '$',
        countryName: 'United States',
        plans:[
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('US', 10),
                pricingLevel: 10,
                price: '89.00',
                typeformId: 'yzUHIzk7',
                name: 'GARAGE QUEEN'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('US', 20),
                pricingLevel: 20,
                price: '139.00',
                typeformId: 'BrBFNMIw',
                name: 'WEEKEND WARRIOR'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('US', 30),
                pricingLevel: 30,
                price: '199.00',
                typeformId: 'snzpVwAH',
                name: 'DAILY DRIVER'
            },
        ]
    },
    AU: {
        currency:'AUD',
        currencySymbol: '$',
        countryName: 'Australia',
        plans:[
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('AU', 10),
                pricingLevel: 10,
                price: '129.00',
                typeformId: 'yzUHIzk7',
                name: 'GARAGE QUEEN'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('AU', 20),
                pricingLevel: 20,
                price: '199.00',
                typeformId: 'BrBFNMIw',
                name: 'WEEKEND WARRIOR'
            },
            {
                paymentUrl: getPaymentUrlByCountryAndPricingLevel('AU', 30),
                pricingLevel: 30,
                price: '299.00',
                typeformId: 'snzpVwAH',
                name: 'DAILY DRIVER'
            },
        ]
    }
}

const getPlansConfigByCountryCode = (code) => {
    return countryPlans[code.toUpperCase()] || countryPlans.NZ;
}

const getPlanByCountryCodeAndPricingLevel = (code, pricingLevel) => {
    return getPlansConfigByCountryCode(code).plans.find(v => v.pricingLevel === Number(pricingLevel));
}

export {countryPlans, getPlanByCountryCodeAndPricingLevel, getPlansConfigByCountryCode};