import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link";



const Header = () => {
    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        document.body.classList.toggle('no-scroll');
        setActive(!isActive);
    }
    return (
        
        <div className="transparent-header">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                        <Link to="/">
                            <img className="siteLogo" src={`https://res.cloudinary.com/carbonclick/image/upload/v1637735191/Enterprise/Vanquish/logo_wmitqm.svg`} style={{height: '46px'}} />
                        </Link>                    
                    </div>
                    <div className="col-auto mobile-0">
                        <div className={isActive ? "menus-outer" :"menus-outer open"}>
                            <ul className="menus">
                                <li><Link className="text-white" to="/why">WHY?</Link></li>
                                <li><Link className="text-white" to="/about">ABOUT</Link></li>
                                <li><Link className="text-white" to="/what-you-get">WHAT YOU GET</Link></li>
                                <li><Link className="text-white" to="/faq">FAQs</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <HashLink className="btn btn-primary" to="/#plansAnchor">OFFSET</HashLink>
                            <a className="toggleBtn ml-3 open" onClick={handleToggle} href="javascript:void(0);">
                                <img className={isActive ? "d-inline-block" : "d-none"} src={`https://res.cloudinary.com/carbonclick/image/upload/v1637748025/Enterprise/Vanquish/toggle_c8uyzx.svg`} style={{height: '46px'}} />
                                <img className={isActive ? "d-none" : "d-inline-block"} src={`https://res.cloudinary.com/carbonclick/image/upload/v1637748025/Enterprise/Vanquish/croll_oinipx.svg`} style={{height: '46px'}} />
                            </a>                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};
    
export default Header;