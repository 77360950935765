import React, { useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getPlanByCountryCodeAndPricingLevel } from "../utils/plansConfig";
import Header from "../pages/components/header";
import FooterSection from "../pages/components/footersection";
import 'url-search-params-polyfill';

const PaymentSuccessPage = ({ }) => {

    const urlParams = new URLSearchParams(location.search);
    const pricingLevel = urlParams.get('pricingLevel');

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        document.body.classList.toggle('no-scroll');
        setActive(!isActive);
    };

    const currentPlanName = getPlanByCountryCodeAndPricingLevel('nz', pricingLevel).name;
    const currentTypeformId = getPlanByCountryCodeAndPricingLevel('nz', pricingLevel).typeformId;

    return (
        <div className="payment-success">
            <div className='bg-primary pt-90'>
                <Header />
            </div>
            <div className="w-100 bg-primary" > 
                <div className="container  color-white text-align-start d-flex align-items-end">
                    <div className="pt-5 pb-80 w-100 title-success payment-info">
                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638957833/Enterprise/Vanquish/lightning_kwtjzu.svg`} />
                        <h2 className="text-shadow mt-0 text-white text-center title-success__message">PAYMENT SUCCESS!</h2>
                        <img src={`https://res.cloudinary.com/carbonclick/image/upload/v1638957833/Enterprise/Vanquish/lightning_kwtjzu.svg`} />
                    </div>
                </div>
            </div>
            <div className={`${pricingLevel === "10" ? "bg-blue" : pricingLevel === "20" ? "bg-grey" : "bg-lite-blue"} py-60`} >
                <div style={{height: "700px"}} className="container" data-tf-widget={currentTypeformId}></div>

                <div
                    id="apply-section"
                    data-tf-widget={currentTypeformId}
                    data-tf-iframe-props={`title=${currentPlanName} -- GENESIS release (31 Jan)`}
                    style={{ display: "none" }}
                >
                </div>
            </div>

            <div className="bg-grey bg-footer"><FooterSection/></div>
        </div>
    )
}


export default PaymentSuccessPage;